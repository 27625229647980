import Vue from 'vue'

// axios
import axios from 'axios'
/* eslint-disable */
import { UNAUTHENTICATED_CODE } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_BACKEND_HOST,
  // timeout: 1000,
})

// Response interceptor
axiosIns.interceptors.response.use(response => (
  // Handle the response here
  response
), error => {
  // Handle unauthenticated users
  if (error.response.status === UNAUTHENTICATED_CODE) {
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

    // Remove userData from localStorage
    localStorage.removeItem('userData')
    // eslint-disable-next-line no-restricted-globals
    location.reload()
  }
  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
