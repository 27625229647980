import Vue from 'vue'
import axios from '@axios'

const NA_VALUE = '- - '
const INITIAL_SENSORS_DATA = {
  brightness: NA_VALUE,
  humidity: NA_VALUE,
  ph: NA_VALUE,
  temperature: NA_VALUE,
  timestamp: 0,
  network: NA_VALUE,
  host: NA_VALUE,
  uuid: NA_VALUE,
  radiation: NA_VALUE,
  conductivity: NA_VALUE,
  upper_sensor_level: NA_VALUE,
  lower_sensor_level: NA_VALUE,
}

const INITIAL_ACTUATORS_DATA = {
  servomotor_1: false,
  servomotor_2: false,
  servomotor_4: false,
  servomotor_5: false,
  servomotor_6: false,
  servomotor_7: false,
  solenoid_valve_1: false,
  solenoid_valve_2: false,
  submersible_pump_1: false,
  submersible_pump_2: false,
}
const EVENTS_TO_EMIT = {
  SET_ACTUATOR: 'set_actuator',
  SET_MAIN_LOOP: 'set_main_loop',
}

// Actuators
const mainLight = {
  icon: 'SunIcon',
  color: 'light-primary',
  title: '189 Lx',
  subtitle: 'Luminosidad',
  customClass: 'mb-2 mb-xl-0 mr-xl-0 mr-lg-5 mb-lg-2',
  state: false,
  actuator: 'mainLight',
}

const phActuator = {
  icon: 'LayersIcon',
  color: 'light-info',
  title: '6,7',
  subtitle: 'pH',
  customClass: 'mb-2 mb-xl-0 mr-xl-0 mr-lg-5',
  state: false,
  actuator: 'phActuator',
}

const airExtractor = {
  icon: 'WindIcon',
  color: 'light-danger',
  title: 'Apagado',
  subtitle: 'Extractor',
  customClass: 'mb-2 mb-sm-0 mr-xl-0 mr-lg-5',
  state: false,
  actuator: 'airExtractor',
}

const waterPump = {
  icon: 'BoxIcon',
  color: 'light-success',
  title: 'Encendido',
  subtitle: 'Bomba',
  customClass: '',
  state: true,
  actuator: 'waterPump',
}

export default {
  namespaced: true,
  state: {
    EVENTS_TO_EMIT,
    actuators: {
      solenoid_valves: [],
      submersible_pumps: [],
      servomotors: [],
      main_loop_running: false,
    },
    loaders: {
      fetchRegisteredDevices: false,
      registerDevice: false,
      deleteDevice: false,
      fetchMeasurementHistory: false,
      pendingActuatorWrite: true,
    },
    selectedDevice: 0,
    registeredDevices: [],
    measurementHistory: [],
    devices: [
      {
        sensorsData: INITIAL_SENSORS_DATA,
        actuatorsData: INITIAL_ACTUATORS_DATA,
      },
    ],
    deviceActuators: [mainLight, phActuator, airExtractor, waterPump],
  },
  getters: {
    device(state) {
      const device = state.devices[state.selectedDevice]
      return {
        ...device,
        processedTimestamp: device.sensorsData.timestamp ? Vue.moment(device.sensorsData.timestamp).format(
          'LLL',
        ) : false,
      }
    },
  },
  mutations: {
    SET_SENSORS_DATA(state, payload) {
      state.devices[0].sensorsData = payload
    },
    SET_REGISTERED_DEVICES(state, payload) {
      state.registeredDevices = payload
    },
    SET_ACTUATOR_DATA(state, payload) {
      state.deviceActuators[payload.actuatorIndex].state = !state
        .deviceActuators[payload.actuatorIndex].state
    },
    SET_MEASUREMENT_HISTORY(state, payload) {
      state.measurementHistory = payload
    },
    SET_ACTUATORS_DATA(state, payload) {
      state.actuators = payload
    },
    SET_PENDING_ACTUATOR_WRITE(state, payload) {
      state.loaders.pendingActuatorWrite = payload
    },
  },
  actions: {
    setPendingActuatorWrite({ commit }, payload) {
      commit('SET_PENDING_ACTUATOR_WRITE', payload)
    },
    setSensorsData({ commit }, payload) {
      commit('SET_SENSORS_DATA', payload)
    },
    setActuatorsData({ commit }, payload) {
      commit('SET_ACTUATORS_DATA', payload)
    },
    fetchRegisteredDevices({ state, commit }) {
      return new Promise((resolve, reject) => {
        state.loaders.fetchRegisteredDevices = true
        axios
          .get('/devices')
          .then(response => {
            commit('SET_REGISTERED_DEVICES', response.data)
            state.loaders.fetchRegisteredDevices = false
            resolve(response)
          })
          .catch(error => {
            state.loaders.fetchRegisteredDevices = false
            reject(error)
          })
      })
    },
    fetchMeasurementHistory({ state, commit }) {
      return new Promise((resolve, reject) => {
        state.loaders.fetchMeasurementHistory = true
        axios
          .get('/devices/all/measurement_history')
          .then(response => {
            commit('SET_MEASUREMENT_HISTORY', response.data)
            state.loaders.fetchMeasurementHistory = false
            resolve(response)
          })
          .catch(error => {
            state.loaders.fetchMeasurementHistory = false
            reject(error)
          })
      })
    },
    registerDevice({ state }, device) {
      return new Promise((resolve, reject) => {
        state.loaders.registerDevice = true
        axios
          .post('/devices', device)
          .then(response => {
            state.loaders.registerDevice = false
            resolve(response.data)
          })
          .catch(error => {
            state.loaders.registerDevice = false
            reject(error)
          })
      })
    },
    deleteDevice({ state }, deviceId) {
      return new Promise((resolve, reject) => {
        state.loaders.deleteDevice = true
        axios
          .delete(`/devices/${deviceId}`)
          .then(response => {
            state.loaders.deleteDevice = false
            resolve(response.data)
          })
          .catch(error => {
            state.loaders.deleteDevice = false
            reject(error)
          })
      })
    },
    updateDevice({ state }, device) {
      return new Promise((resolve, reject) => {
        state.loaders.deleteDevice = true
        axios
          .put(`/devices/${device.id}`, device)
          .then(response => {
            state.loaders.deleteDevice = false
            resolve(response.data)
          })
          .catch(error => {
            state.loaders.deleteDevice = false
            reject(error)
          })
      })
    },
    setActuatorData({ commit }, actuator) {
      commit('SET_ACTUATOR_DATA', actuator)
    },
  },
}
