export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
  {
    path: '/device/:deviceId/settings',
    name: 'device-settings',
    meta: {
      pageTitle: 'Configurar dispositivo',
      breadcrumb: [
        {
          text: 'Configurar dispositivo',
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/device-settings/DeviceSettings'),
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('@/views/pages/pricing/Pricing.vue'),
  },
]
