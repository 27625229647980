import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    loaders: {
      createUser: false,
      login: false,
    },
  },
  mutations: {
  },
  actions: {
    createUser({ state }, payload) {
      return new Promise((resolve, reject) => {
        state.loaders.createUser = true
        axios
          .post('/users/register', payload)
          .then(response => {
            state.loaders.createUser = false
            resolve(response.data)
          })
          .catch(err => {
            state.loaders.createUser = false
            reject(err)
          })
      })
    },
    login({ state }, payload) {
      return new Promise((resolve, reject) => {
        state.loaders.login = true
        axios
          .post('/users/login', payload)
          .then(response => {
            state.loaders.login = false
            resolve(response.data)
          })
          .catch(err => {
            state.loaders.login = false
            reject(err)
          })
      })
    },
  },
}
