import axios from '@axios'

export default {
  namespaced: true,
  state: {
    loaders: {
      updateSettings: false,
      fetchSettings: false,
      createNutrientSolution: false,
      fetchServomotorSettings: false,
      updateServomotorSetting: false,
    },
    settings: {
      deviceSetting: {
        nutrientSolutions: [],
      },
    },
    servomotorSettings: [],
  },
  mutations: {
    SET_SETTINGS(state, settings) {
      state.settings = settings
    },
    SET_SERVOMOTOR_SETTINGS(state, data) {
      state.servomotorSettings = data
    },
  },
  actions: {
    fetchServomotorSettings({ state, commit }) {
      return new Promise((resolve, reject) => {
        state.loaders.fetchServomotorSettings = true
        axios
          .get('/settings/servomotors')
          .then(response => {
            state.loaders.fetchServomotorSettings = false
            commit('SET_SERVOMOTOR_SETTINGS', response.data)
            resolve(response)
          })
          .catch(error => {
            state.loaders.fetchServomotorSettings = false
            reject(error)
          })
      })
    },
    updateServomotorSetting({ state }, servomotorSettingPayload) {
      return new Promise((resolve, reject) => {
        const servomotorSettingId = servomotorSettingPayload ? servomotorSettingPayload.id : 0
        state.loaders.updateServomotorSetting = true
        axios
          .put(`/settings/servomotors/${servomotorSettingId}`, servomotorSettingPayload)
          .then(response => {
            state.loaders.updateServomotorSetting = false
            resolve(response)
          })
          .catch(error => {
            state.loaders.updateServomotorSetting = false
            reject(error)
          })
      })
    },
    updateSettings({ state }, settingsPayload) {
      return new Promise((resolve, reject) => {
        state.loaders.updateSettings = true
        axios
          .post('/settings', settingsPayload)
          .then(response => {
            state.loaders.updateSettings = false
            resolve(response)
          })
          .catch(error => {
            state.loaders.updateSettings = false
            reject(error)
          })
      })
    },
    fetchSettings({ state, commit }, deviceId) {
      return new Promise((resolve, reject) => {
        state.loaders.fetchSettings = true
        axios
          .get(`/devices/${deviceId}`)
          .then(response => {
            state.loaders.fetchSettings = false
            commit('SET_SETTINGS', response.data)
            resolve(response.data)
          })
          .catch(error => {
            state.loaders.fetchSettings = false
            reject(error)
          })
      })
    },
    createNutrientSolution({ state }, nutrientSolutionPayload) {
      return new Promise((resolve, reject) => {
        state.loaders.createNutrientSolution = true
        axios
          .post(`/settings/${nutrientSolutionPayload.settingId}/nutrient-solutions`, nutrientSolutionPayload.ingredient)
          .then(response => {
            state.loaders.createNutrientSolution = false
            resolve(response)
          })
          .catch(error => {
            state.loaders.createNutrientSolution = false
            reject(error)
          })
      })
    },
  },
}
